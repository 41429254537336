import React, { useContext } from "react";
import HomeStyles from "./Home.module.scss";
import IntroductionCarousel from "../IntroductionCarousel";
import Modal from "react-modal";
import ContactUsForm from "../ContactUs/ContactUsForm";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import CaseStudyCarousel from "../CaseStudyCarousel";
import ThemeContext from '../../context/ThemeContext';

function Home(props) {
  const { url = '' } = props;

  const { theme } = useContext(ThemeContext);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Adobe Partner with expertise in Adobe Experience Manager (AEM) CMS{" "}
        </title>
        <meta
          name="description"
          content="Providing comprehensive digital experience development, migration and maintenance support for Marketing, eCommerce, Support and Product Documentation experiences on AEM, Salesforce and SAP along with all complimentary services and applications."
        ></meta>
        <meta property='og:image' content='../../../blogs/heroes/'/>
        <meta property='og:url' content='https://develop.hashouttech.com/' />
      </Helmet>
      {/*<svg ><use xlink href="../../sprite-sheet.svg#algolia"></use></svg>*/}
      <div 
        style={{
          backgroundColor: theme.background,
        }}
        className={HomeStyles.heroContainerWrapper}
      >
        <IntroductionCarousel url={url}/>
        <div className={HomeStyles.hashOut}>
          <div className={HomeStyles.hashOutCircle}></div>
          <div className={HomeStyles.hashOutCircle2}></div>
          <div className={HomeStyles.hashOutCircle3}></div>
          <div className={HomeStyles.hashOutCircle4}></div>
        </div>
      </div>
      <div id="marketing" className={HomeStyles.marketingSolutions}></div>
      <div className={HomeStyles.scroll}>
        <div className={HomeStyles.line}></div>
        <span className={HomeStyles.text}>scroll</span>
      </div>
      <div className={HomeStyles.stickyMenu}>
        <p>Our Expertise</p>
        <div className={HomeStyles.menuContainer}>
          <a href="#marketing">
            <span>
              <img src="../../Marketting.svg"></img>
            </span>
            <span className={HomeStyles.itemNames}>Marketing</span>
          </a>
          <div className={HomeStyles.seperator}></div>
          <a href="#support">
            <span>
              <img src="../../Support.svg"></img>
            </span>
            <span className={HomeStyles.itemNames}>Support</span>
          </a>
          <div className={HomeStyles.seperator}></div>
          <a href="#ecommerce">
            <span>
              <img src="../../ECommerce.svg"></img>
            </span>
            <span className={HomeStyles.itemNames}>ECommerce</span>
          </a>
          <div className={HomeStyles.seperator}></div>
          <a href="#platforms">
            <span>
              <img src="../../Platforms-sticky.svg"></img>
            </span>
            <span className={HomeStyles.itemNames}>Platforms</span>
          </a>
        </div>
      </div>
      <div onClick={openModal} className={HomeStyles.chatbot}>
        <img src="../../Chatbot.svg"></img>
      </div>
      <div
        className={`${HomeStyles.container} ${HomeStyles.gradientContainer}`}
      >
        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.cardTitle}`}
        >
          Digital Marketing Solutions
        </div>
        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.innerCard}`}
        >
          <span>
            <img src={`../../DigitalFoundation.svg`} alt="Digital Foundation" />
          </span>
          <div className={HomeStyles.cardHeader}>Digital Foundation </div>
          <div className={HomeStyles.cardBody}>
            Lay the groundwork to build a secure, scalable unified digital
            foundation that enables you to reduce IT infrastructure costs,
            effectively measure ROI, and deliver personalized <br />
            cross-channel experiences.
          </div>
          {/*<div className={HomeStyles.cardFooter}>
            learn more
            <span>
              <img
                className={HomeStyles.footerArrow}
                src={`../../Vector.svg`}
                alt="Arrow"
              ></img>
            </span>
          </div>*/}
        </div>

        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.innerCard}`}
        >
          <span>
            <img src={`../../CrossChannelExperience.svg`} />
          </span>
          <div className={HomeStyles.cardHeader}>Cross-channel Experience</div>
          <div className={HomeStyles.cardBody}>
            Deliver consistent and relevant customer experiences over both your
            digital and offline channels to drive revenue. We help you design an
            optimal cross-channel campaign orchestration roadmap to provide the
            best customer experiences.
          </div>
        </div>

        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.innerCard}`}
        >
          <span>
            <img src={`../../ContentPersonalizationStrategy.svg`} />
          </span>
          <div className={HomeStyles.cardHeader}>
            Content Personalization Strategy
          </div>
          <div className={HomeStyles.cardBody}>
            Siloed content, customer data, and inefficient tools make it hard to
            measure and optimize customer experiences. We provide strategy to
            stitch all of your data sources to make each customer experience
            truly personalized.
          </div>
        </div>
        <div id="support" className={HomeStyles.section}></div>
      </div>
      <div className={`${HomeStyles.container} ${HomeStyles.supportSection}`}>
        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.cardTitle}`}
        >
          Support Solutions
        </div>
        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.innerCard}`}
        >
          <span>
            <img src={`../../CommunitiesAndKnowledge.svg`} />
          </span>
          <div className={HomeStyles.cardHeader}>
            Communities & Knowledge Base
          </div>
          <div className={`${HomeStyles.cardBody} ${HomeStyles.pt0}`}>
            Help customers help themselves and each other with consistently
            branded community portals and real-time knowledge base at every
            stage of the customer journey to increase retention, reduce support
            costs, and increase your Net Promoter Scores.
          </div>
          {/*<div className={HomeStyles.cardFooter}>
            learn more
            <span>
              <img
                className={HomeStyles.footerArrow}
                src={`../../RedVector.svg`}
                alt="RedArrow"
              ></img>
            </span>
          </div> */}
        </div>

        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.innerCard}`}
        >
          <span>
            <img src={`../../OnlineCaseManagement.svg`} />
          </span>
          <div className={HomeStyles.cardHeader}>Online Case Management</div>
          <div className={HomeStyles.cardBody}>
            Create a branded, seamless, and personalized omnichannel
            self-service experience to reduce customer effort, reduce cost per
            case and demonstrate ROI for your self-service strategy.
          </div>
          <div className={`${HomeStyles.cardBody} ${HomeStyles.pt1}`}>
            <a
              href="../../Hashout-Guide-to-connecting-AEM-and-CRM-for-self-service.pdf"
              target="_blank"
            >
              Read our whitepaper
            </a>{" "}
            on How to Leverage your Existing Investments to Drive Customer
            Self-Service.
          </div>
        </div>

        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.innerCard}`}
        >
          <span>
            <img src={`../../AIMLforSupport.svg`} />
          </span>
          <div className={HomeStyles.cardHeader}>AI/ML for Support</div>
          <div className={HomeStyles.cardBody}>
            Add AI/ML intelligence to your support self-service strategy with
            off-the-shelf and custom solutions to deliver impactful, consistent
            and delightful chatbot and search experiences.
          </div>
          <div className={`${HomeStyles.cardBody} ${HomeStyles.pt1}`}>
            <a href="/blog/State-of-support-chatbot" target="_blank">
              Read our blog
            </a>{" "}
            on State of Support Chatbot.
          </div>
        </div>
        <div id="ecommerce" className={HomeStyles.section}></div>
      </div>
      <div
        className={`${HomeStyles.container} ${HomeStyles.gradientContainer}`}
      >
        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.cardTitle}`}
        >
          Digital Commerce Solutions
        </div>
        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.innerCard}`}
        >
          <span>
            <img src={`../../Commerce-Strategy.svg`} />
          </span>
          <div className={HomeStyles.cardHeader}>Commerce Strategy</div>
          <div className={HomeStyles.cardBody}>
            Create agile commerce ecosystems that boost retail revenues and
            increase customer lifetime value with solid digital commerce
            strategy and technology roadmap. Our team can help you develop
            strategies that reduce your time to market, increase your agility,
            and deliver amazing online experiences.
          </div>
          {/*<div className={HomeStyles.cardFooter}>
            learn more
            <span>
              <img
                className={HomeStyles.footerArrow}
                src={`../../Vector.svg`}
                alt="Arrow"
              ></img>
            </span>
          </div>*/}
        </div>

        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.innerCard}`}
        >
          <span>
            <img src={`../../CMS-Commerce-Integrations.svg`} />
          </span>
          <div className={HomeStyles.cardHeader}>CMS Commerce Integrations</div>
          <div className={HomeStyles.cardBody}>
            Leverage best-in-class digital experience platforms with your
            commerce platform to federate siloed systems and gain access to
            powerful insights and create compelling and personalized,
            world-class shopping experiences.
          </div>
        </div>

        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.innerCard}`}
        >
          <span>
            <img src={`../../Unified Multi-Country Commerce Management.svg`} />
          </span>
          <div className={HomeStyles.cardHeader}>
            Unified Multi-Country Commerce Management
          </div>
          <div className={HomeStyles.cardBody}>
            Scale your multi-country commerce efficiently with a unified
            multi-site management solution that empowers your organization to
            exceed customer expectations, grow profitability, and earn lasting
            customer loyalty.
          </div>
        </div>
        <div id="platforms" className={HomeStyles.section}></div>
      </div>
      <div
        className={`${HomeStyles.container} ${HomeStyles.supportSection} ${HomeStyles.platformSection}`}
      >
        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.cardTitle}`}
        >
          Platforms of Expertise
        </div>
        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.innerCard}`}
        >
          <span>
            <img src={`../../AdobeExperienceCloud.svg`} />
          </span>
          <div className={HomeStyles.cardHeader}>Adobe Experience Cloud</div>
          <div className={HomeStyles.cardBody}>
            <b>(AEM, Analytics, Campaign, Target and Audience Manager)</b> We
            are an Adobe solution integration agency with deep Adobe Experience
            Cloud expertise. Our specialty is launching large, highly scalable
            implementations across the Adobe technology platform suite for our
            fortune 500 customers.
          </div>
          {/*<div className={HomeStyles.cardFooter}>
            learn more
            <span>
              <img
                className={HomeStyles.footerArrow}
                src={`../../RedVector.svg`}
                alt="RedArrow"
              ></img>
            </span>
          </div>*/}
        </div>

        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.innerCard}`}
        >
          <span>
            <img src={`../../Platforms.svg`} />
          </span>
          <div className={HomeStyles.cardHeader}>Platforms</div>
          <div className={HomeStyles.cardBody}>
            <b>(AWS, Google and Azure)</b> Modernize your backend services by
            leveraging our engineering services and reduce your time to market,
            decrease costs, increase efficiencies, and improve performance. Let
            our team help you create a seamless roadmap to modernize your
            backend services by helping you move to the cloud.
          </div>
        </div>

        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={`${HomeStyles.cards} ${HomeStyles.innerCard}`}
        >
          <span>
            <img src={`../../CRMKnowledgeTools&Platforms.svg`} />
          </span>
          <div className={HomeStyles.cardHeader}>
            CRM, Knowledge Tools & Platforms
          </div>
          <div className={HomeStyles.cardBody}>
            <b>(Salesforce, Coveo, Algolia, RightAnswers and LogMeIn)</b> Deploy
            the right combination of practices, platforms and custom tools to
            deliver extraordinary customer experiences. Talk to us to understand
            how our deep business expertise can help you build world-class
            communities and self-service experiences.
          </div>
        </div>
        <div
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
          className={HomeStyles.platformTechnology}
        >
          <h4>Our Technology Partner</h4>
          <ul>
            <li>
              <img src={`../../Adobe.svg`}></img>
            </li>
            <li>
              <img src={`../../service.svg`}></img>
            </li>
            <li>
              <img src={`../../google.svg`}></img>
            </li>
            <li>
              <img src={`../../algolia.svg`}></img>
            </li>
            <li>
              <img src={`../../Coveo.svg`}></img>
            </li>
            <li>
              <img src={`../../Bold.svg`}></img>
            </li>
          </ul>
        </div>
      </div>
      <CaseStudyCarousel />
      <div className={`${HomeStyles.learnMoreSection}`}>
        <div className={`${HomeStyles.learnMoreContents}`}>
          <h4>Would you like to learn more about our services?</h4>
          <p onClick={openModal}>
            Let's connect
            <span>
              <img src="../../RedVector.svg"></img>
            </span>
          </p>
        </div>
      </div>
      <div className={HomeStyles.gotoTop}>
        <div className={`${HomeStyles.referenceButton}`}>
          <a
            href="javascript:void(0);"
            onClick={() => {
              window.scroll(0, 1);
            }}
          >
            <img src="../../Top.svg"></img> Go to Top
          </a>
        </div>
      </div>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          // style={customStyles}
          contentLabel="Example Modal"
          className={HomeStyles.modalOpen}
          overlayClassName={HomeStyles.modalOverlay}
        >
          <div className={HomeStyles.modalHeader}>
            <img onClick={closeModal} src="../../Close.svg"></img>
            <img onClick={closeModal} src="../../CloseMobile.svg"></img>
          </div>
          <div className={HomeStyles.modalBody}>
            <div className={HomeStyles.modalTitle}>
              <h2>Let’s Connect</h2>
              <p>
                Fill out this short form, and we’ll get back to you within one
                working day.
              </p>
            </div>
            <div className={HomeStyles.modalForm}>
              <ContactUsForm url={url} page="Home" section="Let's Connect"/>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Home;

import React from "react";
import FeatureCardStyles from "./FeatureCard.module.scss";

function FeatureCard(props) {
  const cardFeatureList = (
      <ul>
          {props.cardData.map((card) =>
          <li key={card.id}>
              <span><img src={card.iconPath}/></span>{card.content}
          </li>
          )}
      </ul>
  );
  return (
    <div className={FeatureCardStyles.cardWrapper}>
      <div className={FeatureCardStyles.cardContent}>
        <div className={FeatureCardStyles.cardImageWrapper}>
            <img className={FeatureCardStyles.cardImage} src={props.imagePath} alt={props.imageName} />
        </div>
        <h2 className={FeatureCardStyles.cardTitle}>{props.cardTitle}</h2>
        <div className={FeatureCardStyles.cardDetails}>
          {cardFeatureList}
        </div>
      </div>
    </div>
  );
}

export default FeatureCard;

import React from 'react'
import BlogCard from './BlogCard';

function BlogSection() {
    return (
        <div>
            BlogSection
            <BlogCard />
        </div>
    )
}

export default BlogSection

import React from "react";
import HoCaroursel from "../Caroursel";
import CaseStudyStyles from "./CaseStudyCarousel.module.scss";
import "./CaseStudyCarousel.scss";
// import "./CaseStudyCarousel.scss";

function CaseStudyCarousel(props) {
  return (
    <div className={` ${CaseStudyStyles.caseStudySection} caseStudyCarousel`}>
      <div className={`${CaseStudyStyles.caseStudyTitle}`}>
        <p>Our Case study</p>
      </div>
      <HoCaroursel
        className={`${CaseStudyStyles.carouselWrapper}`}
        statusFormatter={(current, total) => `0${current}/0${total}`}
        showIndicators={true}
        showArrows={true}
        infiniteLoop={false}
        autoPlay={false}
        stopOnHover={true}
        showThumbs={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              title="Previous Slide"
              className="control-arrow prev-arrow"
            >
              <img
                className="prev-control-image"
                src="../../carouselNext.svg"
              ></img>
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              title="Next Slide"
              className="control-arrow next-arrow"
            >
              <img
                className="next-control-image"
                src="../../carouselNext.svg"
              ></img>
            </button>
          )
        }
      >
        <div className={`${CaseStudyStyles.carouselContainer}`}>
          <div className={`${CaseStudyStyles.carouselItems}`}>
            <div className={`${CaseStudyStyles.caseDescription}`}>
              <p>
                Delivering more to Citrix Product Documentation customers and
                authors with less overhead{" "}
              </p>
              <p>Migration from CMS to Docs as Code (CI/CD) in JAMstack </p>
              <div className={CaseStudyStyles.brandLogo}>
                <img src="../../Citrix.svg"></img>
              </div>
              {/* <a href="https://youtu.be/kvS5h5domf0?t=91%20" target="_blank">
                learn more
                <span>
                  <img src="../../RedVector.svg"></img>
                </span>
                </a> */}
            </div>
            <div className={`${CaseStudyStyles.caseComment}`}>
              <div className={`${CaseStudyStyles.quoteWrapper}`}>
                <img src="../../quote.svg"></img>
              </div>
              <div className={`${CaseStudyStyles.commentAuthor}`}>
                <p>
                  If you’re looking for a business partner to help with your
                  site, just engage Hashout. Hashout sticks to our budget, is
                  mindful of our requirements and priorities, and keeps our
                  customers’ needs top of mind. With Hashout’s help, customer
                  satisfaction with our site is greater than ever. ”
                </p>
                <div className={`${CaseStudyStyles.authorDetails}`}>
                  <span>
                    <img src="../../beth.svg"></img>
                  </span>
                  <span>
                    <p>Beth Pollock</p>
                    <p>Sr. Principal Information Development at Citrix</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${CaseStudyStyles.carouselContainer}`}>
          <div className={`${CaseStudyStyles.carouselItems}`}>
            <div className={`${CaseStudyStyles.caseDescription}`}>
              <p>
                How Straumann’s Digital Marketing Team Turned Campaign Insights
                into Well-Qualified Leads.
              </p>
              <p>
                Measure more than Vanity metrics with Custom Event tracking
                framework.
              </p>
              <div className={CaseStudyStyles.brandLogo}>
                <img className={CaseStudyStyles.straumannLogo} src="../../Straumann-white.svg"></img>
              </div>
              {/*<a href="https://hashouttech.com/Turn-campaign-insights-in-to-qualifies-leads-Straumann-Casestudy.pdf">
                learn more
                <span>
                  <img src="../../RedVector.svg"></img>
                </span>
              </a>*/}
            </div>
            <div className={`${CaseStudyStyles.caseComment}`}>
              <div className={`${CaseStudyStyles.quoteWrapper}`}>
                <img src="../../quote.svg"></img>
              </div>
              <div className={`${CaseStudyStyles.commentAuthor}`}>
                <p>
                  In a digitally driven organization, we need more than digital
                  vanity metrics. Hashout did a great job capturing all the
                  functions we were looking to have in the solution. I will
                  highly recommend Hashout to any organization looking for a
                  complex data tracking/reporting project. ”
                </p>
                <div className={`${CaseStudyStyles.authorDetails}`}>
                  <span>
                    <img className={CaseStudyStyles.authorImage} src="../../straumann.jpg"></img>
                  </span>
                  <span>
                    <p>Ahmed Badreldin</p>
                    <p>Digital Marketing Manager, Straumann Group</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HoCaroursel>
    </div>
  );
}

export default CaseStudyCarousel;

import React from 'react'

function Table(props) {
    const { 
        columns = [], // [ { key, name } ]
        data 
    } = props;

    return (
        <table>
            <thead>
                <tr>
                    {columns.map(({key, name}) => (
                        <th key={key}>{name}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row) => (
                    <tr key={row.key}>
                        {columns.map(({key}) => (
                            <td key={key}>{row[key]}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default Table

import React from "react";
import HoCaroursel from "../Caroursel";
import IntroductionCarouselStyles from "./IntroductionCarousel.module.scss";
import "./IntroductionCarousel.scss";
import Modal from "react-modal";
import ContactUsForm from "../ContactUs/ContactUsForm";

function IntroductionCarousel(props) {
  const { url } = props;
  
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <HoCaroursel
        showStatus={false}
        showArrows={false}
        infiniteLoop={true}
        autoPlay={true}
        stopOnHover={true}
        showThumbs={false}
        transitionTime={800}
        interval={5000}
        className={`${IntroductionCarouselStyles.introductionSlideWrapper} introductionCarouselWrapper`}
      >
        <div className={IntroductionCarouselStyles.slideWrapper}>
          <div className={`${IntroductionCarouselStyles.heroContainer}`}>
            <p>Creating Incredible </p>
            <h2>cross-channel experiences</h2>
            <p>with Adobe Experience Manager</p>
            {/*<a href="#">
              learn more
              <span>
                <img src="../../RedVector.svg"></img>
              </span>
            </a>*/}
            <div className={`${IntroductionCarouselStyles.heroImageContainer}`}>
              <p>Improving digital experience for top brands like</p>
              <div className={`${IntroductionCarouselStyles.heroImageWrapper}`}>
                <img src="../../Citrix-black.svg"></img>
                <img src="../../Straumann.svg"></img>
              </div>
            </div>
            <div
              className={`${IntroductionCarouselStyles.heroImageContainer} `}
            >
              <a href="https://hashouttech.ac-page.com/aem-whitepaper-landing-page" target="_blank" rel="noopener noreferrer">
                <button
                  className={`${IntroductionCarouselStyles.pointer}`}
                  style={{ fontSize: 18, fontWeight: 400 }}
                > DOWNLOAD THE GUIDE{" "}
                  <span>
                    <img src="../../Vector.svg"></img>
                  </span>
                </button>
              </a>
              {/*<div className={`${IntroductionCarouselStyles.heroImageWrapper}`}>
                <img src="../../Citrix-black.svg"></img>
                <img src="../../Straumann.svg"></img>
                </div>*/}
            </div>
            <div className={IntroductionCarouselStyles.scroll}>
              <div className={IntroductionCarouselStyles.line}></div>
              <span className={IntroductionCarouselStyles.text}>scroll</span>
            </div>
          </div>
        </div>
        <div className={IntroductionCarouselStyles.slideWrapper}>
          <div className={`${IntroductionCarouselStyles.heroContainer}`}>
            <p>Deliver with Agility and Speed</p>
            <h2>Adobe Experience Manager on Cloud</h2>
            <p>Reach out to us for a FREE WORKSHOP</p>
            {/*<a href="#">
              learn more
              <span>
                <img src="../../RedVector.svg"></img>
              </span>
            </a>*/}
            <div
              className={`${IntroductionCarouselStyles.heroImageContainer} `}
            >
              <button
                onClick={openModal}
                className={`${IntroductionCarouselStyles.pointer}`}
              > CONTACT US NOW{" "}
                <span>
                  <img src="../../Vector.svg"></img>
                </span>
              </button>
              {/*<div className={`${IntroductionCarouselStyles.heroImageWrapper}`}>
                <img src="../../Citrix-black.svg"></img>
                <img src="../../Straumann.svg"></img>
                </div>*/}
            </div>
          </div>
        </div>
      </HoCaroursel>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          // style={customStyles}
          contentLabel="Example Modal"
          className={IntroductionCarouselStyles.modalOpen}
          overlayClassName={IntroductionCarouselStyles.modalOverlay}
        >
          <div className={IntroductionCarouselStyles.modalHeader}>
            <img onClick={closeModal} src="../../Close.svg"></img>
            <img onClick={closeModal} src="../../CloseMobile.svg"></img>
          </div>
          <div className={IntroductionCarouselStyles.modalBody}>
            <div className={IntroductionCarouselStyles.modalTitle}>
              <h2>Let’s Connect</h2>
              <p>
                Fill out this short form, and we’ll get back to you within one
                working day.
              </p>
            </div>
            <div className={IntroductionCarouselStyles.modalForm}>
              <ContactUsForm url={url} page="Home" section="Introduction Carousel"/>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default IntroductionCarousel;

import React from "react";
import ButtonStyles from "./Button.module.scss";

function Button(props){
    const buttonClass = ButtonStyles[props.buttonClass];
    return(
        <span className={buttonClass}>
            <button className={ButtonStyles.customButton} onClick={props.clickFunction}>
                {props.buttonText}
            </button>
        </span>
    )
}

Button.defaultProps = {
    buttonClass: 'primary'
}

export default Button;
import React from "react";
import HoCaroursel from "../../Caroursel";
import "./VirtualCarousel.scss";

function virtualCarousel(props) {
  return (
    <HoCaroursel
      showIndicators={props.showIndicator}
      showStatus={false}
      showArrows={false}
      infiniteLoop={true}
      autoPlay={props.autoPlay}
      stopOnHover={true}
      showThumbs={false}
      transitionTime={800}
      interval={5000}
      className={props.className}
      swipeable={false}
    >
      {props.children}
    </HoCaroursel>
  );
}

export default virtualCarousel;
